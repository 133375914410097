import * as Yup from 'yup';

export const SignupFormValidationSchema = Yup.object().shape({
	signupPassword: Yup.string().required().password(15),
	repeatPassword: Yup.string()
		.required()
		.oneOf([Yup.ref('signupPassword')]),
	firstName: Yup.string().checkName().required(),
	lastName: Yup.string().checkName().required(),

	termsOfUseVersion: Yup.bool().oneOf([true]),
	dataPolicyVersion: Yup.bool(),
	rightOfWithdrawalVersion: Yup.bool().oneOf([true]),
	isValidRecaptcha: Yup.bool().oneOf([true]),
});
