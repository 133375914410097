import { ReactNode } from 'react';
import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';

import { Pipeline, usePipeWithChildren, usePipeWithPayload } from '@src/contexts/PipelineContext/PipelineContext';

import AppRoutes from '@src/router/router';
import StyledThemeProvider from '@src/contexts/StyledThemeContext/StyledThemeContext';
import ClientProvider from '@src/api/ClientProvider/ClientProvider';
import RoutingContextProvider from '@src/contexts/RoutingContext/RoutingContext';
import LocaleContextProvider from '@src/contexts/LocaleContext/LocaleContext';
import ToastProvider from '@ui/Toast/ToastContext';
import ErrorPage from '@pages/ErrorPage/ErrorPage';
import UsercentricsContextProvider from '@src/contexts/UsercentricsContext/UsercentricsContext';
import AdobeAnalyticsProvider from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';

const App = () => {
	const pipeline: ReactNode[] = [
		usePipeWithChildren(RoutingContextProvider),
		usePipeWithChildren(StyledThemeProvider),
		usePipeWithChildren(ToastProvider),
		usePipeWithChildren(LocaleContextProvider),
		usePipeWithChildren(ClientProvider),
		usePipeWithChildren(UsercentricsContextProvider),
		usePipeWithChildren(AdobeAnalyticsProvider),
		usePipeWithPayload<ErrorBoundaryProps>(ErrorBoundary, { FallbackComponent: ErrorPage }),
		usePipeWithChildren(AppRoutes),
	];

	return <Pipeline components={pipeline} />;
};

export default App;
